<template>
  <Authenticated>
    <h1>
      <FreeStyleShimmer height="1em" width="10em" v-if="this.project === null">
      </FreeStyleShimmer>
      <span v-else>{{ this.project.name }}</span>
    </h1>

    <div class="graph__navigation">
      <Datepicker v-model="range.start"></Datepicker>
      <Datepicker v-model="range.end"></Datepicker>
    </div>

    <div class="card graph">
      <div class="graph__sensors">
        <ChipSelect
          :value="selectedSensors"
          @input="(v) => (this.selectedSensors = v)"
        >
          <Chip
            v-for="sensor in sensors"
            :key="sensor.sensor_number"
            :value="sensor.sensor_number.toString()"
          >
            Sensor #{{ sensor.sensor_number }}
          </Chip>
        </ChipSelect>

        <input
          type="checkbox"
          name="show-zeros"
          id="show-zeros"
          v-model="showZeros"
        />
        <label for="show-zeros">{{ $t("display-zero-currents") }}</label>
      </div>
      <div class="graph__chart">
        <vue3-chart-js
          id="sensor-graph"
          ref="sensorGraph"
          type="line"
          :data="this.temperatureData"
          :options="this.CHART_OPTIONS"
          :key="this.componentKey"
        />
      </div>
    </div>

    <div class="btn-bar">
      <ThreeDots variation="pulse" :loading="loadingData">
        {{ $t("loading-data") }}
      </ThreeDots>

      <div @click="exportChart()" class="btn btn--action">
        <i class="material-icons-round btn__icon">image</i>
        <span class="btn__description">{{ $t("export-graph") }}</span>
      </div>

      <!--<div class="btn btn--action">
        <i class="material-icons-round btn__icon">backup_table</i>
        <span>Daten exportieren</span>
      </div>-->
    </div>
  </Authenticated>
</template>

<script>
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";
import "chartjs-adapter-date-fns";
import Authenticated from "./Authenticated.vue";
import Datepicker from "vue3-date-time-picker";
import "vue3-date-time-picker/dist/main.css";
import zoomPlugin from "chartjs-plugin-zoom";
import ThreeDots from "../components/ThreeDots/ThreeDots.vue";
import { FreeStyleShimmer } from "vue3-shimmer";
import Chip from "../components/Chip/Chip.vue";
import ChipSelect from "../components/ChipSelect/ChipSelect.vue";
import { formatDate } from "./datetime-utils";
import axios from "axios";
import { pSBC } from "../utils/color-utils";

Vue3ChartJs.registerGlobalPlugins([zoomPlugin]);

const COLOR_SCHEME = [
  "#e41a1c",
  "#377eb8",
  "#4daf4a",
  "#984ea3",
  "#ff7f00",
  "#ffff33",
  "#a65628",
  "#f781bf",
];
/*[
  "#332288",
  "#88ccee",
  "#44aa99",
  "#117733",
  "#999933",
  "#ddcc77",
  "#cc6677",
  "#aa4499",
];*/

export default {
  components: {
    Vue3ChartJs,
    Authenticated,
    Datepicker,
    ThreeDots,
    FreeStyleShimmer,
    Chip,
    ChipSelect,
  },
  data() {
    const monthAgo = new Date();
    monthAgo.setDate(monthAgo.getDate() - 30);

    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    return {
      componentKey: 0,
      project: null,
      chartOptions: this.CHART_OPTIONS,
      temperatureData: {},
      range: {
        start: monthAgo,
        end: tomorrow,
      },
      loadingData: false,
      sensors: [],
      selectedSensors: ["1"],
      cancelSource: null,
      showZeros: false,
    };
  },

  watch: {
    range: {
      handler() {
        this.reloadMeasurements();
      },
      deep: true,
    },
    selectedSensors: {
      handler() {
        this.reloadMeasurements();
      },
      deep: true,
    },
    showZeros: {
      handler() {
        this.reloadMeasurements();
      },
    },
  },
  methods: {
    onZoomComplete({ chart }) {
      const { min, max } = chart.scales.x;
      clearTimeout(this.timer);

      this.timer = setTimeout(() => {
        this.range = {
          start: new Date(min),
          end: new Date(max),
        };

        chart.stop();
        chart.update("none");
      }, 500);
    },
    exportChart() {
      window.open(this.$refs.sensorGraph.chartJSState.chart.toBase64Image());
    },
    makeDataset(dataset, index) {
      let result = [
        {
          label: dataset.sensor_number.toString(),
          borderColor: COLOR_SCHEME[index],
          tension: 0.1,
          borderWidth: 2,
          radius: 2,
          data: dataset.data.map((d) => {
            return {
              x: d.time,
              y: d.value,
            };
          }),
          yAxisID: "valueAxis",
        },
      ];

      if (this.showZeros) {
        result.push({
          label:
            dataset.sensor_number.toString() + ` (${this.$t("zero-current")})`,
          borderColor: pSBC(0.5, COLOR_SCHEME[index]),
          tension: 0.1,
          borderWidth: 2,
          radius: 2,
          data: dataset.data.map((d) => {
            return {
              x: d.time,
              y: d.zero,
            };
          }),
          yAxisID: "zeroAxis",
        });
      }

      return result;
    },
    reloadMeasurements() {
      this.$refs.sensorGraph.chartJSState.chart.zoomScale("x", {
        min: this.range.start,
        max: this.range.end,
      });

      this.loadingData = true;

      this.fetchMeasurements(
        this.range.start,
        this.range.end,
        this.selectedSensors
      ).then((measurements) => {
        if (measurements) {
          const datasets = measurements.flatMap((dataset, index) => {
            return this.makeDataset(dataset, index);
          });

          this.temperatureData = {
            datasets: datasets,
          };
          this.componentKey += 1;
          this.loadingData = false;
        }
      });
    },

    fetchMeasurements(start, end, sensors) {
      if (this.cancelSource) this.cancelSource.cancel();
      this.cancelSource = axios.CancelToken.source();

      return this.$http
        .get(`/measurements/projects/${this.$route.params.id}/data/`, {
          params: {
            start: formatDate(start, "yyyy-MM-ddTHH:mm:ss"),
            end: formatDate(end, "yyyy-MM-ddTHH:mm:ss"),
            sample: 200,
            sensors: sensors ? sensors.join(",") : "",
          },
          cancelToken: this.cancelSource.token,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.$http
      .get(`/measurements/projects/${this.$route.params.id}`)
      .then((response) => {
        this.project = response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    this.$http
      .get(`/measurements/projects/${this.$route.params.id}/sensors/`)
      .then((response) => {
        this.sensors = response.data;
      });

    this.reloadMeasurements();
  },
  created() {
    this.timer = null;

    this.CHART_OPTIONS = {
      animation: false,
      responsive: true,
      maintainAspectRatio: false,
      spanGaps: true,
      tooltips: {
        callbacks: {
          label: (item) => `${item.yLabel}°C`,
        },
      },
      scales: {
        x: {
          position: "bottom",
          type: "time",
          title: {
            text: this.$t("time"),
            display: true,
          },
          time: {
            unit: "day",
          },
        },
        valueAxis: {
          id: "A",
          type: "linear",
          position: "left",
          title: {
            text: this.$t("measurement"),
            display: true,
          },
        },

        zeroAxis: {
          id: "B",
          type: "linear",
          position: "right",
          title: {
            text: this.$t("zero-current"),
            display: true,
          },
        },
      },
      plugins: {
        zoom: {
          zoom: {
            wheel: {
              enabled: true,
            },
            pinch: {
              enabled: true,
            },
            mode: "x",
            onZoomComplete: this.onZoomComplete,
          },
          pan: {
            enabled: true,
            mode: "x",
            threshold: 5,
            onPanComplete: this.onZoomComplete,
          },
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.graph {
  height: 800px;
  display: flex;
  flex-direction: column;

  &__sensors {
  }

  &__chart {
    height: 100%;
  }

  &__navigation {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
}
</style>
