<template>
  <header class="header">
    <img src="@/assets/img/logo.png" alt="" class="header__logo" />
    <div class="header__product-name">Sensor-Dashboard</div>
  </header>
</template>

<style lang="scss">
@import "@/assets/css/variables";

.header {
  position: fixed;
  display: flex;
  align-items: center;

  top: 0;
  left: 0;
  padding: 0 1rem;

  height: $top-bar-height;
  width: 100%;

  background-color: white;
  box-shadow: -1px 2px 3px rgba(0, 0, 0, 0.25);
  z-index: 100;

  &__logo {
    display: block;
    height: 70%;
  }

  &__product-name {
    font-size: 1.2em;
    margin-left: 1rem;
  }
}
</style>
