<template>
  <div
    :class="[
      'chip',
      chipSelect.selectedItems.includes(this.value) ? 'chip--active' : '',
    ]"
    @click="this.toggle()"
  >
    <slot></slot>
  </div>
</template>

<style lang="scss">
@import "Chip";
</style>

<script>
export default {
  props: {
    value: String,
  },
  methods: {
    toggle: function() {
      this.chipSelect.toggle(this.value);
    },
  },
  inject: ["chipSelect"],
  created() {
    console.log(this.chipSelect.value);
  },
};
</script>
