<template>
  <div class="chip-select">
    <slot></slot>
  </div>
</template>

<style lang="scss">
@import "ChipSelect";
</style>

<script>
export default {
  props: {
    value: {
      type: Array[String],
      default: [],
    },
  },
  provide() {
    return {
      chipSelect: this,
    };
  },
  methods: {
    toggle: function(v) {
      if (this.value.includes(v)) {
        this.selectedItems.splice(this.selectedItems.indexOf(v), 1);
      } else {
        this.selectedItems.push(v);
      }
      this.$emit("input", this.selectedItems);
    },
  },
  data() {
    return {
      selectedItems: this.$props.value,
    };
  },
};
</script>
