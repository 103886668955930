<template>
  <div v-if="this.loading" class="three-dots">
    <div :class="`dot-${this.variation}`"></div>
    <div class="three-dots__label"><slot></slot></div>
  </div>
</template>

<style lang="scss">
@import "ThreeDots";

.three-dots {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 1rem;

  &__label {
    margin-left: 2rem;
  }
}
</style>

<script>
export default {
  props: {
    variation: String,
    loading: Boolean,
  },
};
</script>
