<template>
  <AppHeader />

  <main class="authenticated">
    <div class="authenticated__content">
      <div class="authenticated__wrapper">
        <slot></slot>
      </div>
    </div>
  </main>
</template>

<script>
import AppHeader from "../components/AppHeader.vue";

export default {
  components: { AppHeader },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables";
.authenticated {
  padding-top: calc(#{$top-bar-height} + 2rem);
  background-color: $ui-background-color;
  min-height: 100vh;
  margin: 0;

  &__content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__wrapper {
    width: 100%;
    max-width: 1200px;
    // grid-column: 2;
  }
}
</style>
